<template>
  <!--  :style="style" -->
  <van-popup v-model="visibility" class="dialog-confirm">
    <div class="title">服务协议</div>
    <div class="content">
      {{ instructionsText }}
    </div>
    <div class="footer">
      <img v-if="aitosuper" class="footer-btn" @click="close" src="https://cdn.qiumo.net.cn/allsmarketmaterial/aito/aitosuper_btn02.png" alt="">
      <img v-else class="footer-btn" @click="close" src="https://cdn.qiumo.net.cn/allsmarketmaterial/aito/aito_ok.png" alt="">
    </div>
    
  </van-popup>
</template>

<script>
import { instructionsText } from "../data.js";
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    aitosuper: {
      type: Boolean,
      default: false,
    },
    // background: {
    //   type: String,
    //   default: "https://cdn.qiumo.net.cn/allsmarketmaterial/aito/aito_xieyi.png",
    // },
  },
  data() {
    return {
      instructionsText,
    };
  },
  computed: {
    // style() {
    //   return {
    //     background: `url(${this.background}) 100% top/cover no-repeat`,
    //   };
    // },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-confirm {
    box-sizing: border-box;
    width: 720px;
    height: 70%;
    padding: 10px;
    border-radius: 20px;
    background-image: linear-gradient(0deg, #D9DFFF 0%, #F8F9FF 50%, #FEFFFF 100%),
          linear-gradient(#FEFFFF, #FEFFFF);
    overflow: hidden;
    .title{
      width: 100%;
      height: 40px;
      color: #6D48D8;
      font-size: 36px;
      text-align: center;
      margin: 30px auto 20px;
      position: fixed;
    }
    .content{
      margin: 0 auto;
      width: 90%;
      height: calc(100% - 260px);
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: 100px;
      font-weight: normal;
      font-stretch: normal;
      color: #333333;
      font-size: 24px;
      padding-left: 20px;
      white-space: pre-wrap;
     
      .text {
        line-height: 36px;
        letter-spacing: 0px;
      }
    }
    .footer {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .footer-btn{
        width: 80%;
        height: 100px;
      }

      .btn {
        width: 185px;
      }

      .submit {
        padding-left: 18px;
        width: 292px;
      }
    }
  }
}
</style>
